import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from '@apollo/client';
import StaffBanner from '../../../imgs/banners/staff.jpg';
import People from '../../Share/People';
import {
  BannerContainer,
  Banner,
  Spinner,
} from '../../UI';
import { LanguageContext } from '../../../App';

const Staff = () => {
  const { lg } = useContext(LanguageContext);

  const GET_ADMINISTRATIONS = gql`
  {
    administrations {
      data {
        attributes {
          name_zh_tw
          name_en
          job_description_${lg}
          email
          phone
          fax
          office_hour_${lg}
        }
      }
    }
  }
  `;
  const { loading, error, data } = useQuery(GET_ADMINISTRATIONS);

  if (error) { return `Error! ${error.message}`; }

  return (
    <>
      <BannerContainer>
        <Banner src={StaffBanner} />
      </BannerContainer>
      {loading ? <Spinner /> : (
        <People
          title="titles.people.staff.staff"
          data={data.administrations.data}
        />
      )}
    </>
  );
};

export default Staff;
