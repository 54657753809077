import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import ExternalLink from "../Links/ExternalLink";
import CardContent from "./CardContent";
import {
  Card,
  Title,
  SubTitle,
  TextTitle,
  PhotoImage,
  PhotoImageRound,
  SectionContainer,
  Label,
  Text,
} from "../UI";
import fieldsWithIcon from "./Icons";
import baseURL from "../../config/url";

const PeopleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const People = ({ title, data }) => {
  const { t } = useTranslation();

  const getDetails = ({ key, value }) => {
    if (
      key === "__typename" ||
      key.includes("photo") ||
      key === "degree" ||
      key === "status" ||
      !value
    ) {
      return null;
    }
    if (key.includes("name")) {
      return <Title style={{ textTransform: "capitalize" }}>{value}</Title>;
    }
    if (key.includes("job_description")) {
      return <TextTitle style={{ textTransform: "capitalize" }}>{value}</TextTitle>;
    }
    if (key === "graduate_year") {
      return (
        <Text>
          <Label>{t("titles.people.alumni.graduate_year")}</Label>
          {value}
        </Text>
      );
    }
    if (key.includes("thesis")) {
      return (
        <Text>
          <Label>{t("titles.people.alumni.thesis_title")}</Label>
          {value}
        </Text>
      );
    }
    if (key.includes("advisor")) {
      return (
        <Text>
          <Label>{t("titles.people.alumni.advisor")}</Label>
          {value}
        </Text>
      );
    }
    if (key === "cv") {
      return (
        <CardContent
          keyName={key}
          fieldsWithIcon={fieldsWithIcon}
          value={value}
        >
          <ReactMarkdown
            source={value}
            renderers={{
              link: (props) => (
                <a
                  href={
                    props.href.includes("uploads")
                      ? baseURL.concat(props.href)
                      : props.href
                  }
                  target="_blank"
                >
                  {t("titles.people.cv")}
                </a>
              ),
            }}
          />
        </CardContent>
      );
    }
    if (key === "website") {
      if (!value) {
        return null;
      }
      return (
        <CardContent
          keyName={key}
          fieldsWithIcon={fieldsWithIcon}
          value={value}
        >
          <ExternalLink linkText={t("titles.people.website")} link={value} />
        </CardContent>
      );
    }
    return (
      <CardContent
        keyName={key}
        fieldsWithIcon={fieldsWithIcon}
        value={value}
      />
    );
  };

  const getPhoto = ({ key, value }) => {
    // console.log(key, value);

    if (key === "photo_round") {
      return (
        <PhotoImageRound
          src={require(`../../imgs/resources/ntue/${value}`)}
          alt="people_photo"
        />
      );
    }
    if (key === "photo_round_strapi") {
      return (
        <PhotoImageRound
          src={value ? `${baseURL}/uploads/${value}` : null} // use strapi url instead of queried data
          alt="people_photo"
        />
      );
    }
    if (key === "url") {
      return (
        <PhotoImage
          src={value ? `${baseURL}${value}` : null}
          alt="people_photo"
        />
      );
    }
    if (key !== "photo") {
      return null;
    }
    return (
      <PhotoImage
        src={value ? `${baseURL}${value.url}` : null}
        alt="people_photo"
      />
    );
  };

    return (
    <SectionContainer>
      <SubTitle>{title ? t(title) : ""}</SubTitle>
      <div style={{ marginTop: 50 }}>
        {title && title.includes("staff") ? (
          <PeopleGrid>
            {data.map((el) => (
              <Card key={el.id}>
                {"attributes" in el &&
                  "photo" in el.attributes &&
                  "data" in el.attributes.photo &&
                  el.attributes.photo.data !== null && (
                    <div>
                      {Object.entries({
                        ...el.attributes.photo.data.attributes,
                      }).map(([key, value]) => getPhoto({ key, value }))}
                    </div>
                  )}
                {"photo" in el && (
                  <div>
                    {Object.entries(el.photo).map(([key, value]) =>
                      getPhoto({ key, value }),
                    )}
                  </div>
                )}
                <div>
                  {"attributes" in el
                    ? Object.entries(el.attributes).map(([key, value]) =>
                        getDetails({ key, value }),
                      )
                    : Object.entries(el).map(([key, value]) =>
                        getDetails({ key, value }),
                      )}
                </div>
              </Card>
            ))}
          </PeopleGrid>
        ) : (
          data.map((el) => (
            <Card key={el.id}>
              {"attributes" in el &&
                "photo" in el.attributes &&
                "data" in el.attributes.photo &&
                el.attributes.photo.data !== null && (
                  <div>
                    {Object.entries({
                      ...el.attributes.photo.data.attributes,
                    }).map(([key, value]) => getPhoto({ key, value }))}
                  </div>
                )}
              {"photo" in el && (
                <div>
                  {Object.entries(el.photo).map(([key, value]) =>
                    getPhoto({ key, value }),
                  )}
                </div>
              )}
              <div>
                {"attributes" in el
                  ? Object.entries(el.attributes).map(([key, value]) =>
                      getDetails({ key, value }),
                    )
                  : Object.entries(el).map(([key, value]) =>
                      getDetails({ key, value }),
                    )}
              </div>
            </Card>
          ))
        )}
      </div>
    </SectionContainer>
  );

  // return (
  //   <SectionContainer>
  //     <SubTitle>{title ? t(title) : ""}</SubTitle>
  //     <div style={{ marginTop: 50 }}>
  //       {data.map(
  //         (el) => (
  //           (
  //             <Card>
  //               {"attributes" in el &&
  //                 "photo" in el.attributes &&
  //                 "data" in el.attributes.photo &&
  //                 el.attributes.photo.data !== null && (
  //                   <div>
  //                     {Object.entries({
  //                       ...el.attributes.photo.data.attributes,
  //                     }).map(([key, value]) => getPhoto({ key, value }))}
  //                   </div>
  //                 )}
  //               {"photo" in el && (
  //                 <div>
  //                   {Object.entries(el.photo).map(([key, value]) =>
  //                     getPhoto({ key, value })
  //                   )}
  //                 </div>
  //               )}
  //               <div>
  //                 {"attributes" in el
  //                   ? Object.entries(el.attributes).map(([key, value]) =>
  //                       getDetails({ key, value })
  //                     ) // strapi data format
  //                   : Object.entries(el).map(([key, value]) =>
  //                       getDetails({ key, value })
  //                     )}
  //                 {/* format for own data */}
  //               </div>
  //             </Card>
  //           )
  //         )
  //       )}
  //     </div>
  //   </SectionContainer>
  // );
};

People.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default People;
